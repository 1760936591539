import React from "react";
import { Link } from "gatsby";
import ServicePage from "../../components/ServicePage";
import PageMeta from "../../components/PageMeta";

export default function HouseworkPage() {
  return (
    <ServicePage>
      <PageMeta title="QCL Services - Housework" path="services/housework" />
      <h1 className="top">Housework</h1>
      <p className="lead">
        The housework service is commissioned separately to personal care
        requirements.
      </p>
      <p>
        The principal activity of the company is provision of personal care. We
        do, however, provide a “light housework” service.
      </p>
      <p>Typical services provided are:-</p>
      <ul>
        <li>vacuum cleaning</li>
        <li>dusting</li>
        <li>bedmaking/changing</li>
        <li>washing up</li>
        <li>laundry</li>
      </ul>
      <p>The housework service does not include:-</p>
      <ul>
        <li>window cleaning</li>
        <li>washing of paintwork</li>
        <li>carpet shampooing</li>
        <li>moving furniture</li>
      </ul>
      <p>
        Where a deep clean is required, a separate quotation will be provided.
      </p>
      <p>
        In line with Health and Safety limitations, we{" "}
        <span className="underline">do not</span> carry out any tasks that
        require the use of steps or ladders.
      </p>
      <p>
        For further details, please{" "}
        <Link to="/contact-us/">contact our office</Link>.
      </p>
    </ServicePage>
  );
}
